import React from 'react'
import PropTypes from 'prop-types'
import ConfirmLayout from 'src/templates/confirm'
import PageHead from 'src/components/head/PageHead'

// Styles & Images
import SecuritizeIcon from 'static/confirm/images/securitize--icon.svg'

// Main component:
const SecuritizePage = ({ location }) => (
  <ConfirmLayout
    icon={SecuritizeIcon}
    page="securitize"
    deepLinkPath="securitize"
    location={location}
  />
)

// Main component props:
SecuritizePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

SecuritizePage.defaultProps = {}

export default SecuritizePage

// <head> component:
export function Head() {
  return <PageHead page="securitize" noindex />
}
